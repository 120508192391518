let timelineElements = [
    {
      id: 1,
      title: "Software Development Research Assistant",
      location: "University of Georgia",
      description:
        "Assisted Professor in the software engineering of his research project which was to write several codes to detect the seed depth in the soil using PyTorch, Python, TensorFlow, NumPy, Pandas, Keras and Darknet. Conducted a customer discovery program in which I had developed a strategy to conduct various interviews from various industry domains for research Survey and did a proper documentation. Led a team to present impact full our research at NSF Innovation Corps Grand Event.",
      date: "June 2020 - present",
      icon: "work",
    },
    {
      id: 2,
      title: "Master's of Science, Computer Science",
      location: "University of Georgia",
      description:
        "My degree included various courses such as Database, Data Stucture, Alogorithms, Distributed Computing, Cyber Security, Advanced representation Learning",
      date: "August 2019 - August 2021",
      icon: "School",
    },
    {
      id: 3,
      title: "Web Development Intern",
      location: "Art Cad, Mumbai, India",
      description:
        " The role was to design a Web Applications layout and user interface of about 10+pages using frameworks like React.js and Java Script, HTML, CSS using agile development which enhanced the quality of user experience with the company. Deployed the Web application on a live database also took care of the user authentication and authorization.",
      
      date: "May 2018 – December 2019",
      icon: "work",
    },
    {
      id: 4,
      title: "Bachelor's of Engineering, Computer Science",
      location: "Rajiv Gandhi Institute of Technology, Mumbai",
      description:
        "This degree included various courses like Computer Graphics, Database, Machine learning, Artificial intelligence, Object oriented Programming, Computer networks, Advanced Algorithms, ",
      date: "August 2015- May 2019",
      icon: "school",
    },
    {
      id: 5,
      title: "Ashoka Universal School",
      location: "Nashik, India",
      description:
        "School - One of the best international schools in the city.",
      date: "2005-2015",
      icon: "school",
    },
    
  ];
  
  export default timelineElements;
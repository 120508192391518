const portfolios = [
    {
        id: 1,
        category: 'React JS',
        link1: 'https://github.com/karanjad123/Petstore-Full-Stack-React-App-With-Payment',
        link2: 'www.github.com',
        icon1: 'Github',
        
        
        title: 'Full Stack Pet Store WebApp'
    },
    {
        id: 2,
        category: 'Machine Learning',
        link1: 'https://github.com/karanjad123/Seed-Depth-Detection-with-Object-Detection-YOLOv3',
        link2: 'www.github.com',
        icon1: 'Github',

        
        title: 'Seed Depth detection with CNN'
    },
    {
        id: 3,
        category: 'Machine Learning',
        link1: 'https://github.com/karanjad123/seed-depth-prediction-with-regression-model',
        link2: 'www.github.com',
        icon1: 'Github',

        
        title: 'Regression model for Seed Depth'
    },
    {
        id: 4,
        category: 'Javascript',
        link1: 'https://github.com/karanjad123/portfolio1-ReactJS-HTML-SCSS',
        link2: 'www.github.com',
        icon1: 'Github',
        
        title: 'Portfolio Website'
    },
    {
        id: 5,
        category: 'Python',
        link1: 'https://github.com/karanjad123/virtual-assistant-Python',
        link2: 'www.github.com',
        icon1: 'Github',
        
        title: 'Virtual Assistant with Python'
    },
    {
        id: 6,
        category: 'Python',
        link1: 'https://github.com/karanjad123/Music-Player-Python',
        link2: 'www.github.com',
        icon1: 'Github',
        
        title: 'Music Player with Python'
    },
    {
        id: 7,
        category: 'Machine Learning',
        link1: 'https://github.com/karanjad123',
        link2: 'www.github.com',
        icon1: 'Github',
        
        title: 'Hand Gesture Detection with Deep Neural Network'
    }
]

export default portfolios;